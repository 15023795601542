import useMediaQuery from "../hooks/useMediaQuery";
import MindstateVinyl from "../assets/vinyl1.jpg";
import MeltTee from "../assets/shirt-melt.jpeg";
import GodsCassette from "../assets/godsclods1.jpg";
import AnotherMind from "../assets/AnotherMind.jpg";
import Stickers from "../assets/stickers.jpg";
import PrintShirt from "../assets/fingerprint-shirt.jpeg";
import { motion } from "framer-motion";
import YouTube from "react-youtube";

const VideoEmbed = ({ videoIds }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  const opts = {
    height: "200",
    width: "350",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-2 gap-20 ${
        isAboveLarge ? "" : ""
      }`}
    >
      {videoIds.map((videoId) => (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.8 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div key={videoId} className="w-full">
            <iframe
              width={opts.width}
              height={opts.height}
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

const Store = () => {
  const isAboveLarge = useMediaQuery("(min-width:900px)");
  const openLink = (url) => {
    window.open(url, "_blank");
  };

  const videoIds = ["oO4ZKw3ErgE", "2hqqBRUxqqQ", "Odqt51IXkQ8", "qhszP41dtPw"];

  return (
    <section id="store" className="pt-0 pb-0">
      <div
        className={`flex flex-col items-center ${
          isAboveLarge ? "pt-0" : ""
        } align-top md:flex-col md:justify-start md:gap-15 sm:align-top`}
      >
        <motion.div
          className={`md:w-full md:text-center ${isAboveLarge ? "mt-14" : ""}`}
          initial="hidden"
          whileInView={isAboveLarge ? "visible" : null}
          viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
          transition={{ duration: isAboveLarge ? 0.3 : 0 }}
          variants={
            isAboveLarge
              ? { hidden: { opacity: 0, y: 40 }, visible: { opacity: 1, y: 0 } }
              : {}
          }
        >
          <p
            className={`font-integralCF md:text-6xl  ${
              isAboveLarge ? "text-7xl" : "text-4xl"
            } text-center`}
          >
            <span className="border-b-2 inline-block">STORE</span>
          </p>
        </motion.div>

        {/* ^^^^^ STORE TITLE ^^^ */}

        <div
          className={`md:flex justify-center mt-8 ${
            isAboveLarge
              ? "grid grid-cols-2 gap-30"
              : "flex flex-row gap-8 w-[80%]"
          }`}
        >
          {/* FIRST ITEM */}
          <motion.div
            className={`md:w-full ${isAboveLarge ? "sm:w-1/2 " : "w-full"}`}
            initial="hidden"
            whileInView={isAboveLarge ? "visible" : null}
            viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
            transition={{ duration: isAboveLarge ? 0.3 : 0 }}
            variants={
              isAboveLarge
                ? {
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }
                : {}
            }
          >
            <div className="flex flex-col items-center">
              <div
                className={`relative h-50 ${
                  isAboveLarge ? "w-3/5" : "w-full"
                } `}
              >
                <img alt="MindStateVinyl" src={MindstateVinyl} className="" />
              </div>
              <button
                className={`hover:text-black hover:bg-amber-300 bg-offWhite  p-2 text-black font-monda text-semibold text-[14px] mb-10 ${
                  isAboveLarge ? "mt-3" : "mt-4"
                }`}
                onClick={() =>
                  openLink("https://strangelot.bandcamp.com/album/mindstate")
                }
              >
                12" Blue & Black Galaxy Vinyl
              </button>
            </div>
          </motion.div>

          {/* SECOND ITEM */}
          <motion.div
            className={`md:w-full ${isAboveLarge ? "sm:w-1/2" : "w-full"}`}
            initial="hidden"
            whileInView={isAboveLarge ? "visible" : null}
            viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
            transition={{ duration: isAboveLarge ? 0.3 : 0 }}
            variants={
              isAboveLarge
                ? {
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }
                : {}
            }
          >
            <div className="flex flex-col items-center">
              <div
                className={`relative h-50 ${
                  isAboveLarge ? "w-3/5" : "w-full"
                } `}
              >
                <div className="z-10"></div>
                <img
                  alt="gods-clods-cassette"
                  src={GodsCassette}
                  className=""
                />
              </div>
              <button
                className={`hover:text-black hover:bg-amber-300 bg-offWhite  p-2 text-black font-monda text-semibold text-[14px] ${
                  isAboveLarge ? "mt-3" : "mt-4"
                }`}
                onClick={() =>
                  openLink("https://strangelot.bandcamp.com/album/gods-clods")
                }
              >
                Gods & Clods (Cassette)
              </button>
            </div>
          </motion.div>
        </div>

        {/* SECOND DIV ENCASING TWO ITEMS, FOR MORE ROWS COPY A DIV W/ TWO ITEMS IN IT AND PASTE ABOVE/BELOW */}
        <div
          className={`md:flex justify-center mt-1 ${
            isAboveLarge
              ? "grid grid-cols-2 gap-30"
              : "flex flex-row gap-8 w-[80%]"
          }`}
        >
          {/* SECOND ITEM ^^^ */}

          <motion.div
            className={`md:w-full ${isAboveLarge ? "sm:w-1/2" : "w-full"}`}
            initial="hidden"
            whileInView={isAboveLarge ? "visible" : null}
            viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
            transition={{ duration: isAboveLarge ? 0.3 : 0 }}
            variants={
              isAboveLarge
                ? {
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }
                : {}
            }
          >
            <div className="flex flex-col items-center">
              <div
                className={`relative h-50 ${
                  isAboveLarge ? "w-3/5" : "w-full"
                } `}
              >
                <img alt="Stickers" src={Stickers} className="" />
              </div>
              <button
                className={`hover:text-black hover:bg-amber-300 bg-offWhite  p-2 text-black font-monda text-semibold text-[14px] ${
                  isAboveLarge ? "mt-3" : "mt-6"
                }`}
                onClick={() =>
                  openLink(
                    "https://strangelot.bandcamp.com/merch/glow-in-the-dark-sticker-3-pack"
                  )
                }
              >
                Glow In The Dark Stickers
              </button>
            </div>
          </motion.div>

          {/* THIRD ITEM */}

          <motion.div
            className={`md:w-full ${isAboveLarge ? "sm:w-1/2" : "w-full"}`}
            initial="hidden"
            whileInView={isAboveLarge ? "visible" : null}
            viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
            transition={{ duration: isAboveLarge ? 0.3 : 0 }}
            variants={
              isAboveLarge
                ? {
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }
                : {}
            }
          >
            <div className="flex flex-col items-center">
              <div className={`h-50 ${isAboveLarge ? "w-3/5" : "w-full"}`}>
                <img alt="anothermind" src={AnotherMind} className="" />
              </div>
              <button
                className={`hover:text-black hover:bg-amber-300 bg-offWhite  p-2 text-black font-monda text-semibold text-[14px] ${
                  isAboveLarge ? "mt-3" : "mt-6"
                }`}
                onClick={() =>
                  openLink(
                    "https://strangelot.bandcamp.com/album/another-mind-2"
                  )
                }
              >
                Another Mind LP (CD)
              </button>
            </div>
          </motion.div>
        </div>

        {/* FOURTH ITEM WITH DIV */}

        <div
          className={`md:flex mb-10 ${
            isAboveLarge ? "grid grid-cols-2 gap-20" : "gap-40 mt-0"
          }`}
        >
          <motion.div
            className={`md:w-full mt-10 ${
              isAboveLarge ? "sm:w-1/2" : "w-full"
            }`}
            initial="hidden"
            whileInView={isAboveLarge ? "visible" : null}
            viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
            transition={{ duration: isAboveLarge ? 0.3 : 0 }}
            variants={
              isAboveLarge
                ? {
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }
                : {}
            }
          >
            <div className="flex flex-col ml-15 items-center">
              <img
                alt="MeltTee"
                src={MeltTee}
                className="w-full h-full object-contain"
                style={{ width: "40%" }} // Set the width to 100%
              />
              <button
                className="hover:text-black hover:bg-amber-300 bg-offWhite p-2 text-black font-monda text-semibold text-[14px] mt-7"
                onClick={() =>
                  openLink(
                    "https://strangelot.bandcamp.com/merch/strange-lot-melt-logo-t-shirt"
                  )
                } 
              >
                Melt Black Tee
              </button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* *****VIDEO SECTION BELOW***** */}

      <div
        id="video"
        className={`flex mb-20 flex-col items-center ${
          isAboveLarge ? "md:-mt-1" : ""
        } align-top md:flex-col md:justify-start md:gap-16 sm:align-top`}
      >
        <motion.div
          className={`md:w-full md:text-center ${
            isAboveLarge ? "mt-14" : "mt-4"
          }`}
        >
          <p
            className={`font-gridstar font-semibold mb-10 ${
              isAboveLarge ? "text-7xl" : "text-4xl"
            } text-center`}
          >
            <span className="border-b-2 inline-block">VIDEO</span>
          </p>
        </motion.div>

        {/* ^^^^^ VIDEO TITLE ^^^ */}
        <VideoEmbed videoIds={videoIds} />

        {/* You can add more video items if needed */}
        {/* <ItemWithButton imgSrc={YourVideoThumbnail} altText="Video Title" buttonText="Watch Video" /> */}
      </div>
 
      {/* *****LIVE SECTION BELOW***** */}

      <div
        id="live"
        className={`flex flex-col items-center ${
          isAboveLarge ? "md:-mt-1" : ""
        } align-top md:flex-col md:justify-start md:gap-16 sm:align-top`}
      >
        <motion.div
          className={`md:w-full md:text-center ${
            isAboveLarge ? "mt-14" : "mt-4"
          }`}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.8 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p
            className={`font-integralCF ${
              isAboveLarge ? "text-7xl" : "text-4xl"
            } text-center`}
          >
            <span className="border-b-2 inline-block mb-10">LIVE</span>
          </p>
        </motion.div>

        {/* ^^^^^ LIVE TITLE ^^^ */}

        <div
          className={`md:flex md:flex-col justify-center mt-1 mb-40 ${
            isAboveLarge ? "flex flex-col gap-20" : ""
          }`}
        >
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-6xl font-bold mb-1 ${
                  isAboveLarge ? "" : "text-[30px]"
                }`}
              >
                October 27
              </p>
              <p
                className={`text-3xl font-bold mb-1 text-amber-300 ${
                  isAboveLarge ? "" : "text-xl"
                }`}
              >
                {" "}
                W/ BOOGARINS
              </p>
              <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>
                9pm / LONESOME ROSE / SAN ANTONIO, TX
              </p>
              <a
                className={`text-amber-300 text-xl ${
                  isAboveLarge ? "" : "text-base"
                }`}
                href="https://www.prekindle.com/event/74423-boogarins-san-antonio"
                target="_blank"
              >
                Tickets
              </a>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-6xl font-bold mb-1 ${
                  isAboveLarge ? "" : "text-[30px]"
                }`}
              >
                October 28
              </p>
              <p
                className={`text-3xl font-bold mb-1 text-amber-300 ${
                  isAboveLarge ? "" : "text-xl"
                }`}
              >
                {" "}
                W/ BOOGARINS
              </p>
              <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>
                9pm / RUBBER GLOVES / DENTON, TX
              </p>
              <a
                className={`text-amber-300 text-xl ${
                  isAboveLarge ? "" : "text-base"
                }`}
                href="https://dentonrc.com/local-events/?_evDiscoveryPath=/event/1032564364n-boogarins"
                target="_blank"
              >
                Tickets
              </a>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-6xl font-bold mb-1 ${
                  isAboveLarge ? "" : "text-[30px]"
                }`}
              >
                October 29
              </p>
              <p
                className={`text-3xl font-bold mb-1 text-amber-300 ${
                  isAboveLarge ? "" : "text-xl"
                }`}
              >
                {" "}
                W/ BOOGARINS
              </p>
              <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>
                9pm / LAST CONCERT CAFE / HOUSTON, TX
              </p>
              <a
                className={`text-amber-300 text-xl ${
                  isAboveLarge ? "" : "text-base"
                }`}
                href="https://do713.com/events/2024/10/29/boogarins-tickets"
                target="_blank"
              >
                Tickets
              </a>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-6xl font-bold mb-1 ${
                  isAboveLarge ? "" : "text-[30px]"
                }`}
              >
                October 30
              </p>
              <p
                className={`text-3xl font-bold mb-1 text-amber-300 ${
                  isAboveLarge ? "" : "text-xl"
                }`}
              >
                {" "}
                W/ BOOGARINS
              </p>
              <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>
                9pm / SLOW & STEADY / DALLAS, TX
              </p>
              <a
                className={`text-amber-300 text-xl ${
                  isAboveLarge ? "" : "text-base"
                }`}
                href="https://www.prekindle.com/event/99373-boogarins-at-oakcliff-trompo-dallas"
                target="_blank"
              >
                Tickets
              </a>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-6xl font-bold mb-1 ${
                  isAboveLarge ? "" : "text-[30px]"
                }`}
              >
                October 31
              </p>
              <p
                className={`text-3xl font-bold mb-1 text-amber-300 ${
                  isAboveLarge ? "" : "text-xl"
                }`}
              >
                LEVITATION W/ THE BLACK ANGELS, MDOU MOCTAR & BOOGARINS
              </p>
              <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>
                9pm / STUBBS / AUSTIN, TX
              </p>
              <a
                className={`text-amber-300 text-xl ${
                  isAboveLarge ? "" : "text-base"
                }`}
                href="https://www.seetickets.us/event/The-Black-Angels-Mdou-Moctar-Boogarins/610337"
                target="_blank"
              >
                Tickets
              </a>
            </div>
          </motion.div>

          {/* PAST SHOWSSSS BELOW  */}

          {/* 

        <motion.div
          
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.8 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
        <div className="font-monda ml-3 mb-14">
  <p className={`text-6xl font-bold mb-1 ${isAboveLarge ? "" : "text-[30px]"}`}>March 08</p>
  <p className={`text-3xl font-bold mb-1 text-amber-300 ${isAboveLarge ? "" : "text-xl"}`}>DO512 SMOKEHOUSE SHOWCASE</p>
  <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>9pm / HOTEL VEGAS / AUSTIN, TX</p>
</div>
</motion.div>

<motion.div
          
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.8 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
<div className="font-monda ml-3 mb-14">
  <p className={`text-6xl font-bold mb-1 ${isAboveLarge ? "" : "text-[30px]"}`}>March 11</p>
  <p className={`text-3xl font-bold mb-1 text-amber-300 ${isAboveLarge ? "" : "text-xl"}`}>HOMETOWN HEROES SXSW PREPARTY</p>
  <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>5pm / 13TH FLOOR / AUSTIN, TX</p>
</div>
</motion.div>

<motion.div
          
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.8 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
<div className="font-monda ml-3 mb-14">
  <p className={`text-6xl font-bold mb-1 ${isAboveLarge ? "" : "text-[30px]"}`}>March 14</p>
  <p className={`text-3xl font-bold mb-1 text-amber-300 ${isAboveLarge ? "" : "text-xl"}`}>PSYCHED! RADIO SHOWCASE</p>
  <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>1pm / DRINKS BACKYARD / AUSTIN, TX</p>
</div>
</motion.div>

<motion.div
          
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.8 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
<div className="font-monda ml-3 mb-14">
  <p className={`text-6xl font-bold mb-1 ${isAboveLarge ? "" : "text-[30px]"}`}>March 15</p>
  <p className={`text-3xl font-bold mb-1 text-amber-300 ${isAboveLarge ? "" : "text-xl"}`}>SPAACE CAMP SHOWCASE</p>
  <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>5pm / HOTEL VEGAS / AUSTIN, TX</p>
</div>
</motion.div>

<motion.div
          
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.8 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
<div className="font-monda ml-3 mb-14">
  <p className={`text-6xl font-bold mb-1 ${isAboveLarge ? "" : "text-[30px]"}`}>March 15</p>
  <p className={`text-3xl font-bold mb-1 text-amber-300 ${isAboveLarge ? "" : "text-xl"}`}>PSYCHED! RADIO SHOWCASE</p>
  <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>9pm / THE MET / AUSTIN, TX</p>
</div>
</motion.div>

<motion.div
          
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.8 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
<div className="font-monda ml-3 mb-0">
  <p className={`text-6xl font-bold mb-1 ${isAboveLarge ? "" : "text-[30px]"}`}>March 16</p>
  <p className={`text-3xl font-bold mb-1 text-amber-300 ${isAboveLarge ? "" : "text-xl"}`}>HOWDY GALS X PSYCHED RADIO! SHOWCASE</p>
  <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>8pm / HOLE IN THE WALL / AUSTIN, TX</p>
</div>
</motion.div>
 */}
        </div>
        <div>
          <div className="flex flex-col mt-10 mb-3">
            <p className="font-monda text-center mb-1">Contact</p>
            <p className="font-monda">SSTRANGELOT@GMAIL.COM</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Store;
