import { useState } from "react";
import SocialMediaIcons from "../components/SocialMediaIcons";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const EPKLink = ({ page, selectedPage, setSelectedPage }) => {
  const lowerCasePage = page.toLowerCase();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <AnchorLink
      className={`${selectedPage === lowerCasePage ? "text-amber-300" : ""} transition duration-500 ${isHovered ? "text-amber-300" : "text-offWhite2"}`}
      href={`#${lowerCasePage}`}
      onClick={() => setSelectedPage(lowerCasePage)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {page}
      
    </AnchorLink>
  );
};

const EPKNavbar = ({ selectedPage, setSelectedPage }) => {
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isAboveSmallScreens = useMediaQuery("(min-width: 768px)");
  const navbarText = isAboveSmallScreens ? "p-2" : "p-1 text-lg mt-1";

  return (
    <nav className={` ${navbarText} z-40 w-full top-0 `}>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.8 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="flex flex-col md:flex-row items-center justify-between mx-5 "
      >
        {/* NAVIGATION LINKS */}
        <div className={`flex ${navbarText}  md:flex-row items-center gap-6 md:gap-16 font-integralCF text-offWhite text-1xl`}>
          <EPKLink
            page="BIO"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
          <EPKLink
            page="PHOTOS"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
          <EPKLink
            page="VIDEO"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
        </div>

        {/* SOCIAL MEDIA ICONS */}
        <div className={`flex items-center ${isAboveSmallScreens ? "mt-4" : "mt-2"} md:mt-0`}>
          <SocialMediaIcons />
        </div>
      </motion.div>
    </nav>
  );
};

export default EPKNavbar;
