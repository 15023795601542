import React, { useState } from "react";
import SpotifyIcon from "../assets/spotify6.png";
import SpotifyHoverIcon from "../assets/thumbnail_spotify6.png";
import InstagramIcon from "../assets/instagramm.png";
import InstagramHoverIcon from "../assets/thumbnail_instagramm.png";
import BandcampIcon from "../assets/bcblack.png";
import BandcampHoverIcon from "../assets/thumbnail_bcyellow.png";
import YoutubeIcon from "../assets/yticonnew_edited.png";
import YoutubeHoverIcon from "../assets/thumbnail_yticonnew_edited.png";
import FacebookIcon from "../assets/whitelogofbook.png";
import FacebookHoverIcon from "../assets/facebook-yellow-circle-logo-01_edited.png";

const SocialMediaIcons = () => {
  const [isSpotifyHovered, setIsSpotifyHovered] = useState(false);
  const [isInstagramHovered, setIsInstagramHovered] = useState(false);
  const [isBandcampHovered, setIsBandcampHovered] = useState(false);
  const [isYoutubeHovered, setIsYoutubeHovered] = useState(false);
  const [isFacebookHovered, setIsFacebookHovered] = useState(false);

  const iconSize = "h-7 w-7"; // Set a common size for regular icons
  const hoverIconSize = "h-10 w-10";
  const hoverIconPosition = "mt-1";
  const hoverfbookSize = "h-[29px] w-[29px] mt-0"; // Set a larger size for hover icons

  return (
    <div className="flex justify-center md:justify-start my-2 gap-7 mb-0 mt-1">
      <a
        className={`relative  transition-transform ease-in-out duration-500 ${iconSize}`}
        href="https://open.spotify.com/artist/3IqWV1jEfJHTg9WosOQOeg?si=KR_vse80TUCCBjDCtAELTw"
        target="_blank"
        rel="noreferrer"
        onMouseEnter={() => setIsSpotifyHovered(true)}
        onMouseLeave={() => setIsSpotifyHovered(false)}
      >
        <img
          alt="spotify"
          src={isSpotifyHovered ? SpotifyHoverIcon : SpotifyIcon}
          className={`w-full h-full absolute top-0 left-0 transform ${
            isSpotifyHovered ? "-translate-y-1" : "translate-y-0"
          } ${isSpotifyHovered ? hoverIconSize : ""} ${
            isSpotifyHovered ? hoverIconPosition : ""
          }`}
        />
      </a>
      <a
        className="transition duration-500 h-7 w-7"
        href="https://instagram.com/strangelot"
        target="_blank"
        rel="noreferrer"
        onMouseEnter={() => setIsInstagramHovered(true)}
        onMouseLeave={() => setIsInstagramHovered(false)}
      >
        <img
          alt="instagram"
          src={isInstagramHovered ? InstagramHoverIcon : InstagramIcon}
        />
      </a>
      <a
        className="transition duration-500 h-7 w-7"
        href="https://strangelot.bandcamp.com/"
        target="_blank"
        rel="noreferrer"
        onMouseEnter={() => setIsBandcampHovered(true)}
        onMouseLeave={() => setIsBandcampHovered(false)}
      >
        <img
          alt="bandcamp"
          src={isBandcampHovered ? BandcampHoverIcon : BandcampIcon}
        />
      </a>
      <a
        className="transition duration-500 h-7 w-7"
        href="https://www.youtube.com/channel/UC_80znmVJaW5ONHZUBb8gAA"
        target="_blank"
        rel="noreferrer"
        onMouseEnter={() => setIsYoutubeHovered(true)}
        onMouseLeave={() => setIsYoutubeHovered(false)}
      >
        <img
          alt="youtube"
          src={isYoutubeHovered ? YoutubeHoverIcon : YoutubeIcon}
        />
      </a>
      <a
        className={`relative transition-transform h-9 w-9 duration-500 `}
        href="https://facebook.com/strangelot"
        target="_blank"
        rel="noreferrer"
        onMouseEnter={() => setIsFacebookHovered(true)}
        onMouseLeave={() => setIsFacebookHovered(false)}
        style={{
          padding: isFacebookHovered ? "0px" : "",
          margin: isFacebookHovered ? "" : "",
        }}
      >
        <img
          alt="facebook"
          src={isFacebookHovered ? FacebookHoverIcon : FacebookIcon}
          className={`w-full h-full absolute top-[-4px] right-2 mb-1 transform ${
            isFacebookHovered ? "-translate-y-0 " : ""
          } ${isFacebookHovered ? hoverfbookSize : ""}`}
          style={{ padding: isFacebookHovered ? '' : '' }}
        />
      </a>
    </div>
  );
};
export default SocialMediaIcons;
