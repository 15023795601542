import { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const DotGroup = ({ selectedPage, setSelectedPage }) => {
  const Dot = ({ targetId, children }) => {
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
      setSelectedPage(targetId);
      setIsActive(!isActive);
    };

    return (
      <AnchorLink
        className={`relative w-3 h-3 rounded-full ${
          selectedPage === targetId ? "bg-amber-300" : "bg-white"
        } ${isActive ? "border-2 border-amber-300" : ""}`}
        href={`#${targetId}`}
        onClick={handleClick}
      >
        {children}
      </AnchorLink>
    );
  };

  return (
    <div className="flex flex-col gap-6 fixed top-[60%] right-7">
      <Dot targetId="home"></Dot>
      <Dot targetId="store"></Dot>
      <Dot targetId="video"></Dot>
      <Dot targetId="live"></Dot>
    </div>
  );
};

export default DotGroup;
