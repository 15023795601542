import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";
import BandPhoto1 from "../assets/bandphoto.jpg";
import BandPhoto2 from '../assets/EPKIMAGE13.png'
const EPKLanding = ({ setSelectedPage }) => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 768px)");

  return (
    <div
      id="home"
      className={`flex justify-center items-center py-10 bg-center bg-no-repeat relative ${isAboveMediumScreens ? 'h-[600px]' : 'h-[260px]'}`}
      style={{
        // marginTop: isAboveMediumScreens ? '0' : '56px', // Adjust based on the height of your fixed navbar
        backgroundImage: `url(${BandPhoto2})`,
        backgroundSize: isAboveMediumScreens ? "contain" : "contain",
        backgroundPosition: "center",
        maxWidth: "100%",
        maxHeight: "90%", // Adjust the maximum width of the image container
        margin: "auto", // Center the image horizontally
      }}
    >
      {/* MAIN div */}

      {/* Gold Line at the Bottom */}
      {/* <div
        className={`bg-gold h-1 w-full absolute ${isAboveMediumScreens ? 'top-0 left-0' : 'bottom-full left-0'}`}
      /> */}
    </div>
  );
};

export default EPKLanding;
