import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";
import MainImage from "../assets/strange-lot-logo-neoblack5.gif";

const Landing = ({ setSelectedPage }) => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  return (
    <div
      id="home"
      className={`flex bg-slow justify-center items-center bg-no-repeat md:h-[300px] lg:h-[550px] relative ${isAboveMediumScreens ? '' : 'h-[190px]'} `}
      style={{
        // marginTop: isAboveMediumScreens ? '0' : '56px', // Adjust based on the height of your fixed navbar
        backgroundImage: `url(${MainImage})`,
        backgroundSize: isAboveMediumScreens ? "cover" : "contain",
        backgroundPosition: "top"
      }}
    >
      {/* MAIN div */}

      {/* Gold Line at the Bottom */}
      {/* <div
        className={`bg-gold h-1 w-full absolute ${isAboveMediumScreens ? 'top-0 left-0' : 'bottom-full left-0'}`}
      /> */}
    </div>
  );
};

export default Landing;
